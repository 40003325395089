
import Group66 from './../images/Group 66.png';
import Rectangle from './../images/Rectangle.png';
import camera from './../images/camera.png';
import Vector from './../images/Vector 5.png';
import logo from './../images/SHABARI.AI_2-01.png';
import Form from 'react-bootstrap/Form';
import { useRef, useState } from 'react';
import Col from 'react-bootstrap/Col';
import './Fruite-Details.css';
import axios from 'axios';
import FruitDetails from './Fruite-Details';
import { render } from '@testing-library/react';

const FileUploader = (props) => {
    const inputFile = useRef(null)
    const inputFile1 = useRef(null)
    const inputFile2 = useRef(null)
    const [file, setFile] = useState();
    const [file1, setFile1] = useState();
    const [file2, setFile2] = useState();
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [validated, setValidated] = useState(false);
    const [fruitDetailsData, setFruitDetailsData] = useState(null); // State to store fruit details data
    const [loader, setloader] = useState(false);
    const [error, setError] = useState(null);
    const [mobileNumber, setMobileNumber] = useState('');
    const images1=props.passedValue.newValue+1

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        
        const form = event.currentTarget;
        
        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }
        
        // Update mobile number state
        // const mobileNumberValue = form.elements.validationCustom01.value;
        const mobileNumberValue = "Nitish"
        // if (mobileNumberValue >=1000000000 && mobileNumberValue <= 9999999999){
        if (mobileNumberValue ==="Nitish"){
        setMobileNumber(mobileNumberValue);
        setloader(true);
        // Call store_mobile API to store the mobile number
        try {
            const storeMobileResponse = await axios.post('https://api.shabari.ai/store_mobile', {
                mobile_number: mobileNumberValue
            });
            // Mobile number stored successfully
            console.log("Mobile number stored successfully:", storeMobileResponse.data);
        } catch (error) {
            // Handle errors from storeMobileResponse, e.g., display an error message or log the error
            console.error('Error storing mobile number:', error);
            return; // Exit the function early if there's an error
        }}
        else{
            alert("server being updated");
            console.error('Error storing mobile number:', error);
            return;
        }
        if (props.passedValue.newValue === "Ginger" || props.passedValue.newValue === "Pineapple" || props.passedValue.newValue === "Mango"){
            try {
            // Call the login function to get the access token
            const token = await login();
            console.log(token);
            console.log("file" + file);
            console.log("file 1" + file1);
            console.log("file 2" + file2);
            console.log("input file" + inputFile);
            console.log("input file 1" + inputFile1);
            console.log("input file 2" + inputFile2);
            // Call the process_fruits API with the access token
            const apiName = 'https://api.shabari.ai/scanpurityextrafruits/processs_extrafruits';
            const formData = new FormData();
            formData.append('image1', file);  // Use file state
            formData.append('image2', file1); // Use file1 state
            formData.append('image3', file2); // Use file2 state
            formData.append('Ripening', true);
            formData.append('Maturity', true);
            formData.append('Sweetness', true); 
            const response = await axios.post(apiName, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'fruit': props.passedValue.newValue,
                },
            });
            // Extract necessary data from the response
            console.log("resule obtained " + response.data);
            const { result, Ripening, Maturity, Sweetness } = response.data;
            const passedValue1 = props.passedValue.newValue;
            const temp = props.passedValue.temp;
            console.log("Result: ", response.data.result);
            console.log("ripening: ", response.data.Ripening);
            console.log("Maturity: ", response.data.Maturity);
            console.log("Sweetness: ", response.data.Sweetness);
            setFruitDetailsData({ passedValue1, Maturity, Sweetness, Ripening, temp });
            setloader(false);
        }
        catch (error) {
            // Handle errors
            setError(error.message);
            console.log("error " + error.message);
        }
    }
    else{
        try {
        // Call the login function to get the access token
        const token = await login();
        console.log(token);
        console.log("file" + file);
        console.log("file 1" + file1);
        console.log("file 2" + file2);
        console.log("input file" + inputFile);
        console.log("input file 1" + inputFile1);
        console.log("input file 2" + inputFile2);
        // Call the process_fruits API with the access token
        const apiName = 'https://api.shabari.ai/process_fruits';
        const formData = new FormData();
        formData.append('image1', file);  // Use file state
        formData.append('image2', file1); // Use file1 state
        formData.append('image3', file2); // Use file2 state
        formData.append('Ripening', true);
        formData.append('Maturity', true);
        formData.append('Sweetness', true);
        const response = await axios.post(apiName, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'fruit': props.passedValue.newValue,
            },
        });
        // Extract necessary data from the response
        console.log("resule obtained " + response.data);
        const { result, Ripening, Maturity, Sweetness } = response.data;
        const passedValue1 = props.passedValue.newValue;
        const temp = props.passedValue.temp;
        console.log("Result: ", response.data.result);
        console.log("ripening: ", response.data.Ripening);
        console.log("Maturity: ", response.data.Maturity);
        console.log("Sweetness: ", response.data.Sweetness);
        console.log("temperature :", temp);
        // const { Ripening, Maturity, Sweetness } = data;
        // console.log(Ripening);
        // console.log(Maturity);
        // console.log(Sweetness);
        // Set the fruit details data in state
        setFruitDetailsData({ passedValue1, Maturity, Sweetness, Ripening, temp });
        setloader(false);
    }
    catch (error) {
        // Handle errors
        setError(error.message);
        console.log("error " + error.message);
    }
}};
    const login = async () => {
        try {
            const password = "140979";
            const response = await axios.post('https://api.shabari.ai/login', {
                username: 'goplow',
                password: password,
            });
            return response.data.access_token;
        } catch (error) {
            console.error('Error during login:', error);
            throw error;
        }
    };
    const showOpenFileDlg = () => {
        inputFile.current.click();
    }
    const showOpenFileDlg1 = () => {
        inputFile1.current.click();
    }
    const showOpenFileDlg2 = () => {
        inputFile2.current.click();
    }
    const handleChangeImage = e => {
        setShow(true);
        setFile(e.target.files[0]);  // Store the file directly
    }
    const handleChangeImage1 = e => {
        setShow1(true);
        setFile1(e.target.files[0]);  // Store the file directly
    }
    const handleChangeImage2 = e => {
        setShow2(true);
        setFile2(e.target.files[0]);  // Store the file directly
    }
    if (fruitDetailsData == null) {
        // if (props.passedValue==="Mango"){
        //     return (
        //         <div className={`container-fluid uploader bg-image-${props.passedValue}`}>
        //             <div className="modal show model-open" tabIndex={-1} style={{ display: 'block' }}>
        //                 <div className="text-center logo">
        //                     {/* <p className="color-green">SHABARI.<span className="color-white">AI</span></p> */}
        //                     <img src={logo}  style={{ width: '220px', height: '130px' }}/>
        //                 </div>
        //                 <div className="modal-dialog  modal-xl modal-dialog-centered">
        //                     <div className="modal-content">
        //                         <div className="modal-body">
        //                             <div className="p-3">
        //                                 <h3 className="text-center font-weight-bold">Safeda {props.passedValue} - <span className='green-color'>SHABARI</span>.AI</h3>
        //                             </div>
        //                             <div className="container col-sm-9">
        //                                 <div className="container col-sm-9 p-0">
        //                                     <p className="text-left font-weight-bold">Upload images</p>
        //                                     <div className="row">
        //                                         <div className="col" onClick={showOpenFileDlg}>
        //                                             <input type='file' id='file' ref={inputFile} style={{ display: 'none' }} onChange={handleChangeImage} />
        //                                             {!show ? (
        //                                                 <><img src={Rectangle} className="img-camera-bg" /><img src={camera} className="img-camera " /></>) : (
        //                                                 <img src={URL.createObjectURL(file)} alt="img" className="img-camera-bg" />)}
        //                                         </div>
        //                                         <div className="col" onClick={showOpenFileDlg1}>
        //                                             <input type='file' id='file1' ref={inputFile1} style={{ display: 'none' }} onChange={handleChangeImage1} />
        //                                             {!show1 ? (
        //                                                 <><img src={Rectangle} className="img-camera-bg" /><img src={camera} className="img-camera " /></>) : (
        //                                                 <img src={URL.createObjectURL(file1)} alt="img" className="img-camera-bg" />)}
        //                                         </div>
        //                                         <div className="col" onClick={showOpenFileDlg2}>
        //                                             <input type='file' id='file2' ref={inputFile2} style={{ display: 'none' }} onChange={handleChangeImage2} />
        //                                             {!show2 ? (
        //                                                 <><img src={Rectangle} className="img-camera-bg" /><img src={camera} className="img-camera " /></>) : (
        //                                                 <img src={URL.createObjectURL(file2)} alt="img" className="img-camera-bg" />)}
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                                 <hr />
        //                                 <div className="container col-sm-9 pt-3">
        //                                     <div className="row justify-content-md-center">
        //                                         <p className="color-green mb-0 font-weight-bold col-sm-12 text-left">Instructions<img src={Group66} /></p>
        //                                         <div className="row col-sm-10 pl-4 flash-font pt-3 justify-content-md-center">
        //                                             <div className='row col-sm-6 justify-content-md-center'>
        //                                                 <p className="mb-0 pr-3"><img className="pr-2" src={Vector} />Good light</p>
        //                                                 <p className="pr-3 mb-0"><img className="pr-2" src={Vector} />No Flash</p>
        //                                             </div>
        //                                             <div className='row col-sm-6 justify-content-md-center'>
        //                                                 <p className="pr-3 mb-0"><img className="pr-2" src={Vector} />No Filter</p>
        //                                                 <p className="pl-2 mb-0"><img className="pr-2" src={Vector} />Full Product</p>
        //                                             </div>
        //                                         </div>
        //                                         <div className="row col-sm-7 pt-3">
    
        //                                             <div className="col"><img src={require(`./../images/${props.passedValue}1.png`)} /></div>
        //                                             <div className="col"><img src={require(`./../images/${props.passedValue}2.png`)} /></div>
        //                                             <div className="col"><img src={require(`./../images/${props.passedValue}3.png`)} /></div>
        //                                         </div>
        //                                     </div>
        //                                     <hr />
        //                                     <div className="container">
        //                                         <Form noValidate validated={validated} onSubmit={handleSubmit}>
        //                                             <div className="row justify-content-md-center">
        //                                                 <div className="col-sm-7 pt-4 input-group mb-3">
        //                                                     <Form.Group as={Col} sm="12" controlId="validationCustom01">
        //                                                         <Form.Control
        //                                                             required
        //                                                             className='form-control text-center col-sm-12 mb-3 p-2 flash-font'
        //                                                             type="text"
        //                                                             placeholder="Enter Mobile Number"
        //                                                         />
        //                                                         <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        //                                                         <Form.Control.Feedback type="invalid">
        //                                                             Please provide mobile no
        //                                                         </Form.Control.Feedback>
    
        //                                                     </Form.Group>
        //                                                     <div className='col-sm-12'>
        //                                                         <button type='submit' className="btn-fruit btn-success col-sm-12 text-center font-weight-bold">Generate
        //                                                             Result</button>
        //                                                     </div>
        //                                                 </div>
        //                                             </div>
        //                                         </Form>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //             {loader == true && <div className="loading">Loading&#8230;</div>}
        //         </div>
        //     );
        // }
        // else{
            return (
                <div className={`container-fluid uploader bg-image-${props.passedValue.newValue}`}>
                    <div className="modal show model-open" tabIndex={-1} style={{ display: 'block' }}>
                        <div className="text-center logo">
                            {/* <p className="color-green">SHABARI.<span className="color-white">AI</span></p> */}
                            <img src={logo}  style={{ width: '220px', height: '130px' }}/>
                        </div>
                        <div className="modal-dialog  modal-xl modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="p-3">
                                        <h3 className="text-center font-weight-bold" style={{fontSize:"20px", marginTop:"-20px"}}>{props.passedValue.newValue} - <span className='green-color'>SHABARI</span>.AI</h3>
                                    </div>
                                    <div className="container col-sm-9">
                                        <div className="container col-sm-9 p-0"style={{height:"130px"}}>
                                            <p className="text-left font-weight-bold">Upload images</p>
                                            <div className="row">
                                                <div className="col" onClick={showOpenFileDlg} style={{height:"200px", width:"200px"}}>
                                                    <input type='file' id='file' ref={inputFile} style={{ display: 'none'}} onChange={handleChangeImage} />
                                                    {!show ? (
                                                        <><img src={Rectangle} className="img-camera-bg" /><img src={camera} className="img-camera " /></>) : (
                                                        <img src={URL.createObjectURL(file)} alt="img" className="img-camera-bg" />)}
                                                </div>
                                                <div className="col" onClick={showOpenFileDlg1} style={{height:"200px", width:"200px"}}>
                                                    <input type='file' id='file1' ref={inputFile1} style={{ display: 'none' }} onChange={handleChangeImage1} />
                                                    {!show1 ? (
                                                        <><img src={Rectangle} className="img-camera-bg" /><img src={camera} className="img-camera " /></>) : (
                                                        <img src={URL.createObjectURL(file1)} alt="img" className="img-camera-bg" />)}
                                                </div>
                                                <div className="col" onClick={showOpenFileDlg2} style={{height:"200px", width:"200px"}}>
                                                    <input type='file' id='file2' ref={inputFile2} style={{ display: 'none' }} onChange={handleChangeImage2} />
                                                    {!show2 ? (
                                                        <><img src={Rectangle} className="img-camera-bg" /><img src={camera} className="img-camera " /></>) : (
                                                        <img src={URL.createObjectURL(file2)} alt="img" className="img-camera-bg" />)}
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="container col-sm-9 pt-3">
                                            <div className="row justify-content-md-center">
                                                <p className="color-green mb-0 font-weight-bold col-sm-12 text-left">Instructions<img src={Group66} /></p>
                                                <div className="row col-sm-10 pl-4 flash-font pt-3 justify-content-md-center" style={{fontSize:"12px"}}>
                                                    <div className='row col-sm-6 justify-content-md-center'>
                                                        <p className="mb-0 pr-3"><img className="pr-2" src={Vector} />Good light</p>
                                                        <p className="pr-3 mb-0"><img className="pr-2" src={Vector} />No Flash</p>
                                                    </div>
                                                    <div className='row col-sm-6 justify-content-md-center'>
                                                        <p className="pr-3 mb-0"><img className="pr-2" src={Vector} />No Filter</p>
                                                        <p className="pl-2 mb-0"><img className="pr-2" src={Vector} />Full Product</p>
                                                    </div>
                                                </div>
                                                <div className="row col-sm-7 pt-3">
                                                    <div className="col"><img src={require(`./../images/${props.passedValue.newValue}1.png`)} style={{height:"50px"}}/></div>
                                                    <div className="col"><img src={require(`./../images/${props.passedValue.newValue}2.png`)} style={{height:"50px"}}/></div>
                                                    <div className="col"><img src={require(`./../images/${props.passedValue.newValue}3.png`)} style={{height:"50px"}}/></div>
                                                </div>
                                                <div><p style={{color: "green"}}>Please use white background for clicking images.</p></div>
                                            </div>
                                        
                                            <div className="container">
                                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                                    <div className="row justify-content-md-center">
                                                        <div className="col-sm-7 pt-4 input-group mb-3">
                                                            {/* <Form.Group as={Col} sm="12" controlId="validationCustom01">
                                                                <Form.Control
                                                                    required
                                                                    className='form-control text-center col-sm-12 mb-3 p-2 flash-font'
                                                                    type="text"
                                                                    placeholder="Enter Mobile Number"
                                                                />
                                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please provide mobile no
                                                                </Form.Control.Feedback>
                                                            </Form.Group> */}
                                                            <div className='col-sm-12'>
                                                                <button type='submit' className="btn-fruit btn-success col-sm-12 text-center font-weight-bold">Generate
                                                                    Result</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loader == true && <div className="loading">Loading&#8230;</div>}
                </div>
            );
        // }

    }
else{
    return (
        <div className={`container-fluid uploader bg-image-${props.passedValue.newValue}`}>
            <FruitDetails fruitDetails={fruitDetailsData} />
        </div>
        );}
}
export default FileUploader;
