import watermelon from './../images/Rectangle_A.png';
import banana from './../images/Rectangle_B.png';
import pineapple from './../images/Rectangle_.png';
import mango from './../images/Rectangle_Ma.png';
import ginger from './../images/ginger.png';
import papaya from './../images/Rectangle_Pa.png';
// import arrow from './../images/Arrow.png';
import logo from './../images/SHABARI.AI_2-01.png';
import './home.css';
import FruitDetails from './Fruite-Details';
import { React, useRef, useState } from 'react';
const HomePage = ({ onValueChange }) => {
    const [temp, setTemp] = useState(null);
    const [value, setValue] = useState('');
    const handleChange = (event) => {
        const newValue = event.target.alt;

        onValueChange({"newValue":newValue,"temp":temp});
    };
    const handleChange1 = () => {
        window.location.href = "https://audo.shabari.ai/"
    };

    const location = (position) => {
        // console.log(position);
        // console.log(position.coords.latitude)
        // console.log(position.coords.longitude)
        const latitude = position.coords.latitude
        const longitude = position.coords.longitude;
        const api_key = "bc674b5c01002b5acb140be7ceb2ae6b"
        // const url = "https://api.openweathermap.org/data/2.5/weather?lat={position.coords.latitude}&lon={position.coords.longitude}&appid={api_key}&units=metric"
        const url = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${api_key}&units=metric`;

        fetch(url)
        .then(response => response.json())
        .then(data => {
            // Logging the temperature
            // console.log("Temperature:", data.main.temp + "°C");
            const temp = data.main.temp;
            // console.log(temp);
            setTemp(temp);
        })
        .catch(error => {
            console.error("Error fetching weather data:", error);
        });
    }
    const fail_to_fetch = () => {
        console.log("fail to fetch the location");
    }

    const get_location = async() => {
        const loc = navigator.geolocation.getCurrentPosition(location,fail_to_fetch);
    }
    get_location();
    // console.log(temp);
    return (
        <div className="container-fluid bg-image-home">
            
            <div className="text-center logo">
                    {/* <p className="color-green">SHABARI.<span className="color-white">AI</span></p> */}
                    <img src={logo}  style={{ width: '220px', height: '130px' }}/>
                </div>
            <div className="modal" tabIndex={-1} style={{ display: 'block' }}>
                <div className="modal-dialog  modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="pt-3 container">
                                <h3 className="font-weight-bold">Fruit</h3>
                               <p>Select fruit to scan purity</p>
                            </div>
                            <div className="container col-sm-12">
                                <div className="container col-sm-10 pb-3">
                                    <div className="row justify-content-md-center">
                                        <div className='row col-sm-6'>
                                            <div className='col-4'>
                                                <img onClick={handleChange} src={papaya} alt="Papaya" className='img-home-page' />
                                                <p className="font-weight-bold font-size-home-fruit" >Papaya</p>
                                            </div>
                                            <div className='col-4'>
                                                <img onClick={handleChange} src={banana} className='img-home-page' alt="Banana"  />
                                                <p className="font-weight-bold font-size-home-fruit">Banana</p>
                                            </div>
                                            <div className='col-4'>
                                                <img onClick={handleChange} src={mango} className='img-home-page' alt="Mango"  />
                                                <p className="font-weight-bold font-size-home-fruit">Safeda Mango</p>
                                            </div>
                                        </div>
                                        <div className='row col-sm-6'>
                                            <div className='col-4'>
                                                <img onClick={handleChange} src={pineapple}  className='img-home-page' alt="Pineapple"  />
                                                <p className="font-weight-bold font-size-home-fruit">Pineapple</p>
                                            </div>
                                            <div className='col-4' >
                                                <img src={watermelon} onClick={handleChange1} className='img-home-page' alt="Watermelon"  />
                                                <p className="font-weight-bold font-size-home-fruit">Watermelon</p>
                                            </div>
                                            <div className='col-4'>
                                                <img src={ginger} onClick={handleChange} className='img-home-page' alt="Ginger"  />
                                                <p className="font-weight-bold font-size-home-fruit">Ginger</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default HomePage;